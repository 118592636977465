.adm-list {
  --header-font-size: var(--adm-font-size-7);
  --prefix-width: 'auto';
  --prefix-padding-right: 12px;
  --align-items: center;
  --active-background-color: var(--adm-color-border);
  --border-inner: solid 1px var(--adm-color-border);
  --border-top: solid 1px var(--adm-color-border);
  --border-bottom: solid 1px var(--adm-color-border);
  --padding-left: 12px;
  --padding-right: 12px;
  --font-size: var(--adm-font-size-9);
  --extra-max-width: 70%;

  &-header {
    color: var(--adm-color-weak);
    font-size: var(--header-font-size);
    padding: 8px var(--padding-right) 8px var(--padding-left);
  }
  &-body {
    background-color: var(--adm-color-background);
    overflow: hidden;
    font-size: var(--font-size);
  }
  &-body-inner {
    margin-top: -1px;
  }
  &-default {
    .adm-list-body {
      border-top: var(--border-top);
      border-bottom: var(--border-bottom);
    }
  }
  &-card {
    margin: 12px;
    .adm-list-body {
      border-radius: 8px;
    }
    .adm-list-header {
      padding-left: 0;
    }
  }
}

.adm-list-item {
  display: block;
  padding-left: var(--padding-left);
  position: relative;
  background-color: var(--adm-color-background);
  line-height: 1.5;
  &-title,
  &-description {
    color: var(--adm-color-weak);
    font-size: var(--adm-font-size-main);
  }
  &-content {
    display: flex;
    align-items: var(--align-items);
    justify-content: flex-start;
    border-top: var(--border-inner);
    padding-right: var(--padding-right);
    &-prefix {
      width: var(--prefix-width);
      flex: none;
      padding-right: var(--prefix-padding-right);
    }
    &-main {
      flex: auto;
      padding: 12px 0;
    }
    &-extra {
      flex: none;
      padding-left: 12px;
      font-size: var(--adm-font-size-7);
      // color: rgb(255, 223, 13);
      color: var(--qk-color-secondary);
      max-width: var(--extra-max-width);
    }
    &-arrow {
      flex: none;
      display: flex;
      align-items: center;
      margin-left: 4px;
      color: var(--adm-color-light);
      font-size: 19px;
    }
  }
  &-disabled {
    cursor: not-allowed;
    && > .adm-list-item-content > * {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}

a.adm-list-item {
  &:active:not(.adm-list-item-disabled) {
    background-color: var(--active-background-color);
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      width: 100%;
      bottom: -1px;
      left: 0;
      border-bottom: var(--border-inner);
    }
  }
}