$classPrefix: 'adm-picker-view';

.#{$classPrefix}-column,
.#{$classPrefix}-mask {
  --item-height: 40px;
  --adm-color-border: var(--qk-border-color);
}
.#{$classPrefix}-column-item {
  color: var(--qk-core-picker-item-color);
  font-size: 16px;
  &-selected {
    color: var(--qk-color-title);
  }
}