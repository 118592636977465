.adm-cascader-view {
  --height: auto;

  &-tabs {
    &.adm-tabs {
      --title-font-size: var(--adm-font-size-6);
      --content-padding: none;
    }
  }

  &-header {
    &-title {
      max-width: 84px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &-content {
    > .adm-check-list {
      --border-inner: none;
      --border-bottom: none;
      --border-top: none;
    }
    height: var(--height);
    overflow-y: auto;
  }

  &-item {
    font-size: var(--adm-font-size-6);
    color: #323233;
    &-active {
      // color: rgb(255, 223, 13);
      color: var(--qk-color-secondary);
    }
  }
  .adm-list-inner {
    margin-bottom: 0;
  }

  &-skeleton {
    padding: 16px 12px;
    .adm-skeleton {
      margin-bottom: 16px;
      --height: 18px;
      --width: 80%;
      --border-radius: 2px;
      &.adm-cascader-view-skeleton-line {
        &-3 {
          --width: 90%;
        }
        &-4 {
          --width: 60%;
        }
      }
    }
  }
}