$class-prefix-mask: 'qk-mask';

.#{$class-prefix-mask} {
  --z-index: var(--qk-mask-z-index, 1000);

  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index);
  display: block;
  width: 100%;
  height: 100%;
  &-aria-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  &-content {
    z-index: 1;
  }
}
