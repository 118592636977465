@class-prefix-picker: ~'adm-cascader';

.@{class-prefix-picker} {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.@{class-prefix-picker}-header {
  flex: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  &-button {
    font-size: var(--adm-font-size-7);
    display: inline-block;
    padding: 4px 4px;
    &.cancel {
      color: #0000009A;
    }
    &.confirm {
      background-color: #fff !important;
      // color: rgb(255, 223, 13);
      color: #000;
    }
  }
  &-title {
    padding: 4px 4px;
    font-size: var(--adm-font-size-7);
    color: var(--adm-color-text);
    text-align: center;
    flex: 1;
  }
}

.@{class-prefix-picker}-body {
  flex: auto;
  height: 100%;
  width: 100%;
  > .adm-cascader-view {
    --height: 310px;
  }
}