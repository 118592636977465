html {
  touch-action: manipulation;
}

body {
  font-size: 16px;
}

input{
  outline: none;
}

:root:root {
  /// 配置主题色
  --qk-color-primary: rgb(44, 100, 227);
  /// 点击下的主题色
  --qk-color-primary-shade: rgb(171, 193, 244);
  --qk-color-primary-tint: rgb(171, 193, 244);
  --divider-color: #e9e9e9;

  //登录按钮选中背景色
  --qk-color-login-btn:rgb(44, 100, 227);
  --qk-color-login-btn-disabled: rgb(171, 193, 244);

  --qk-color-placeholder: #A9ACB0;

  --qk-color-disabled: rgb(171, 193, 244);
  --qk-color-danger: rgb(255, 138, 0);
  --qk-color-weak: #A9ACB0;
  --qk-border-color: #ECEDEE;

  --page-color: rgb(255, 255, 255); //页面背景色
  --mask-color: rgba(0, 0, 0, 0.3); //遮罩背景色
  --font-text-color: #666666;
  --font-title-color: #333333;
  --font-weak-color: #999999;

  --qk-font-family-medium: Roboto-Medium, PingFangSC-Medium;
  --qk-font-family-bold-number: DINAlternate-Bold, DIN-Bold, DIN;

  --qk-nav-font-size: 16px;
  --qk-form-title-size: 15px;
  --qk-font-size-tiny: 10px;
  --qk-font-size-desc: 12px;
  --qk-font-size-text: 14px;
  --qk-font-size-subtitle: 16px;
  --qk-font-size-title: 18px;
}

:root {
  .qk-button {
    height: 48px;
    white-space: normal;
  }
  .qk-modal-bottom {
    border-radius: 0;
  }

  .qk-modal-center {
    left: 16px;
    right: 16px;
    border-radius: 20px;
    .qk-button {
      --border-radius: 0;
    }

    .qk-button-ghost {
      color: #333333;
    }
  }

  .qk-action-sheet-button-item-name {
    font-weight: 500;
    font-family: var(--qk-font-family-medium);
  }
}

ion-toolbar {
  --min-height: 48px;
  box-shadow: none !important;
}
