$class-prefix-action-sheet: 'qk-action-sheet';

.#{$class-prefix-action-sheet} {
  &-popup {
    > .qk-popup-body {
      // border-top-left-radius: 8px;
      // border-top-right-radius: 8px;
      overflow: hidden;
    }
  }
  &-extra {
    display: flex;
    justify-content: center;
    color: var(--qk-color-weak);
    font-size: var(--qk-font-size-text);
    padding: 18px 12px;
    border-bottom: 1px solid var(--qk-border-color);
  }
  &-button-list {
    border-bottom: 1px solid var(--qk-border-color);
  }
  &-button-item-wrapper {
    border-bottom: 1px solid var(--qk-border-color);
    &:last-child {
      border-bottom: none;
    }
  }
  &-button-item {
    text-align: center;
    display: block;
    background-color: var(--qk-color-background);
    padding: 14px 16px;
    min-height: 48px;
    &:active {
      background-color: #eeeeee;
    }
    &-disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.4;
    }
    &-name {
      color: var(--qk-color-title);
      font-size: var(--qk-font-size-subtitle);
    }
    &-description {
      font-size: var(--qk-font-size-desc);
      color: var(--qk-color-weak);
      padding-top: 4px;
    }
    &-danger {
      .#{$class-prefix-action-sheet}-button-item-name {
        color: var(--qk-color-danger);
      }
    }
    &-bold {
      .#{$class-prefix-action-sheet}-button-item-name {
        font-weight: bold;
      }
    }
  }

  &-cancel {
    background-color: var(--qk-color-box);
    padding-top: 8px;
  }
}
