$classPrefix: 'qk-datePicker';

.#{$classPrefix} {
  &-modal::part(content) {
    padding-top: 0 !important;
  }
  span{
    font-size: 14px;
  }

  &-modal {
    --item-height: 55px;
    padding: 0;

    &-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      // background: var(--qk-core-modal-header-background);
      height: 60px;
      align-items: center;
      font-size: 16px;
      color: var(--qk-color-text);
      padding: 0 20px;
      font-weight: 500;
      &-confirm {
        color: #141414;
      }
    }
    &-date-picker-view {
      width: 100%;
      padding: 0 20px;
    }
  }
}

.qk-custom-date-picker {
  .adm-picker-header-title {
    font-size: 32px;
    font-family: Roboto-Medium, PingFangSC-Medium;
    font-weight: 500;
    color: #333333;
    line-height: 32px;
  }

  .adm-picker-header {
    .adm-picker-header-button {
      min-width: 80px;

      &:active,
      &:hover {
        color: var(--adm-color-primary);
      }
    }

    // >a:hover {
    //   color: @primary-focus-border-color;
    // }
  }

  .adm-picker-view-column-item {
    color: rgba(0, 0, 0, 0.26);
  }

  .adm-picker-view-column-wheel {
    >.adm-picker-view-column-item:not([data-selected="false"]) {
      // color: var(--qk-color-primary);
      color: #fff;
    }
  }

  .adm-picker-view-column:nth-child(2) {
    &:after {
      position: absolute;
      left: 0px;
      top: calc(50% - var(--item-height)/2);
      height: var(--item-height);
      width: 100%;
      content: "";
      z-index: -1;
      // background-color: rgb(255, 250, 217);
      background-color: var(--qk-color-primary)
    }
  }

  .adm-picker-view-column:first-child {
    &:after {
      position: absolute;
      left: 0px;
      top: calc(50% - var(--item-height)/2);
      height: var(--item-height);
      width: 100%;
      content: "";
      z-index: -1;
      border-radius: 8px 0px 0px 8px;
      background-color: var(--qk-color-primary)
    }
  }

  .adm-picker-view-column:nth-child(3) {
    &:after {
      position: absolute;
      left: 0px;
      top: calc(50% - var(--item-height)/2);
      height: var(--item-height);
      width: 100%;
      content: "";
      z-index: -1;
      border-radius: 0px 8px 8px 0px;
      background-color: var(--qk-color-primary)
    }
  }

  .adm-picker-view-mask-middle {
    border: none;
  }

  .adm-picker-view-column-item-label {
    // padding: 0px 50px;
  }
}