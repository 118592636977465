.qk-button {
  height: var(--qk-btn-height, 50px);
  overflow: auto;

  &-disabled {
    --background: var(--qk-color-disabled);
    --background-activated: var(--qk-color-disabled);
    --background-focused: var(--qk-color-disabled);
    --background-hover: var(--qk-color-disabled) ;
    opacity: 1;
  }

  &-ghost {
    --background: transparent;
    // --border-color: var(--qk-color-primary);
    // --border-width: 1px;
    border: 1px solid var(--qk-color-primary);
    border-radius: var(--border-radius);
    color: var(--qk-color-primary);
  }

  &-clear {
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    opacity: 1;
    color: var(--qk-color-primary);
    font-weight: 400;
  }
}
