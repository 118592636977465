ion-toolbar, #qk-navbar {
  --padding-start: 10px;
  padding-top: 30px;
  // padding: 0 10px;
}
ion-title, #qk-navbar-title {
  font-size: var(--qk-nav-font-size);
  font-weight: bold;
  font-family: var(--qk-font-family-medium);
  padding-left: 0;
  padding-right: 0;
}
