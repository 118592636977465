.qk-spinner {
  inset: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  contain: strict;
  cursor: pointer;
  z-index: 2;
  pointer-events: none;
  &-inner{
    $spinnerWidth: 50px;
    width: $spinnerWidth;
    height: $spinnerWidth;
    // background: var(--qk-color-dark);
    border-radius: 5px;
    opacity: 0.7;
    // position: absolute;
    // left: calc(50% - $spinnerWidth/2);
    // top: calc(50% - $spinnerWidth/2);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    // ion-spinner svg {
    //   width: 19%;
    //   height: 100px;
    // }

    &-bubbles {
      width: 40px;
      height: 40px;
      // transform: scale(1.8);
      color: var(--qk-color-primary) !important;
    }

  }
}

