@import '../../global.scss';
$classPrefix: 'radioGroup';

.#{$classPrefix}-container {
  display: flex;
  justify-content: space-between;
  > span {
    margin-right: 30 * $hd;
  }
  &-content {
    display: flex;

    &-box {
      display: flex;
      align-items: center;
      margin-left: 20px;
      > span {
        margin-left: 20 * $hd;
        font-size: 28 * $hd;
      }
    }
  }
}

.#{$classPrefix}-item {
  width: 30 * $hd;
  height: 30 * $hd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2 * $hd solid;

  border-color: var(--qk-core-radio-default);

  > div {
    opacity: 0;
    width: 16 * $hd;
    height: 16 * $hd;
    border-radius: 50%;
    background: var(--qk-color-primary);
  }
}
.#{$classPrefix}-item-checked {
  border-color: var(--qk-color-primary);
  > div {
    opacity: 1;
  }
}
