$class-prefix-checkbox: 'qk-checkbox';

.#{$class-prefix-checkbox} {
  display: flex;
  flex-direction: row;
  align-items:flex-end;
  margin-bottom: 10px;

  #icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  // #text {
  //   margin-left: 6px;
  //   span {
  //     color: var(--qk-color-primary);
  //   }
  // }
}