$classPrefix: 'DefaultFormItemWrapper';

.#{$classPrefix} {
  position: relative;
  border-radius: 8px 8px 8px 8px;
  // border: 1px solid rgb(204, 204, 204);
  min-height: 57px;
  // height: 57px;
  padding: 10px 6px 10px 14px;

  display: flex;
  flex-direction: row;
  align-items: center;

  overflow: visible;

  margin-bottom: 18px;

  // background: rgba(237, 238, 240, 1);
  background:#F0F1F5;
  box-shadow: 0px 5px 25px rgba(217, 217, 217, 0.1);
  color: rgba(32, 32, 38, 1);
  font-weight: bold;
  font-size: 14px;
  box-sizing: border-box;

  &.focused {
    border-color: var(--qk-color-primary);
    border-width: 2px;
  }

  &.error {
    border-color: var(--qk-color-danger);
  }
  
  .#{$classPrefix}-title {
    font-size: var(--qk-font-size-text);
    color: var(--font-weak-color);

    &.floating {
      display: none;
      position: absolute;
      left: 12px;
      top: -8px;
      font-size: 12px;
      margin-right: 0px;
      color: transparent;
      line-height: 12px;
      // background-color: rgb(244, 243, 249);
      padding: 0px 4px;
    }

    &.focused {
      color: var(--qk-color-primary);
    }

    &.error {
      color: var(--qk-color-danger);
    }
  }

  .#{$classPrefix}-input {
    display: block;
    flex: 1;
    color: #333333;
    .qk-input {
      // --font-size: 16px;
    }
  }

  .#{$classPrefix}-required {
    position: absolute;
    width: 12px;
    height: 20px;
    margin-top: 3px;
    margin-left: 3px;
    color: var(--qk-color-danger);
  }

  .#{$classPrefix}-rightArrow {
    color: var(--qk-color-primary);
    font-size: 16px;
    margin-right: 10px;
  }
}