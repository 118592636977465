.confirm-model{
    padding-top: 16px;
    .confirm-title{
        font-size: 17px;
        color: rgb(3, 3, 3);
        font-weight: 600;
    }
    .confirm-desc{
        width: 100%;
        box-sizing: border-box;
        padding: 0 12px;
        font-size: 13px;
        color: rgb(3, 3, 3);
        padding-bottom: 10px;
        line-height: 18px;
    }
    .confirm-btn{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid rgba(237, 237, 237, 1);
        .qk-button-ghost{
            width: 50%;
            border: 0;
            border-right: 1px solid rgba(237, 237, 237, 1);
            margin: 0;
            color: rgb(255, 223, 13)!important;
        }
        .qk-button-ghost:last-child{
            border-right: 0 !important;
        }
        .qk-modal-button2{
            color: rgb(51, 51, 51) !important;
        }
    }
    .confirm-btn.only{
        width: 90%;
        border-top: 0;
        margin: 0 auto 20px;
        .qk-button-ghost{
            border-right: 0;
            width: 100%;
            text-align: center;
            border-radius: 2px;
            background: linear-gradient(93.87deg, rgba(255, 150, 48, 1) 3.17%, rgba(255, 82, 82, 1) 97.04%);
            box-shadow: 0px 2px 8px rgba(52, 0, 0, 0.21);
            color: #fff !important;
        }
    }
}

.qk-modal-center{
    padding: 0 !important;
}