$classPrefix: 'qk-TakePhoto';

.#{$classPrefix}-container {
  // background: #FFFFFF;
  box-shadow: 0px 4px 4px 0px rgba(17, 73, 46, 0.04);
  border-radius: 4px 4px 4px 4px;
  overflow: visible;
  position: relative;
  display: flex;
  &-error {
    border: 1px solid var(--qk-color-danger);
  }

  --width: 97px;
  --height: 95px;
  --padding: 5px;

  --title-height: auto;
  --title-line-height: 1.2;
  --title-padding: none;

  padding: var(--padding);

  .#{$classPrefix}-image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .#{$classPrefix}-success {
    position: absolute;
    bottom: 50px;
    width: 24px !important;
    height: 24px !important;
  }

  .#{$classPrefix}-placehodler {
    position: relative;
    // width: var(--width);
    // height: var(--height);
    flex: 1;
    border-radius: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    overflow: hidden;

    &-icon {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }
  .#{$classPrefix}-exampleImg {
    background-color: #333333;
    flex: 1;
    height: 100%;
    margin-right: 16px;
    border-radius: 8px;
  }

  .#{$classPrefix}-title {
    font-size: 12px;
    color: #333333;
    line-height: var(--title-line-height);
    text-align: center;
    max-width: var(--width);
    word-break: keep-all;
    height: var(--title-height);
    white-space: pre-line;
    padding: var(--title-padding);
  }

  .#{$classPrefix}-desc {
    position: absolute;
    bottom: 15px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    z-index: 1;
    width: 118px;
    height: 26px;
    line-height: 26px;
    border-radius: 6px;
    background: var(--qk-color-primary);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 5px;
  }

  .#{$classPrefix}-icon {
    position: absolute;
    bottom: 50px;
    z-index: 1;
    width: 44px !important;
    height: 44px !important;
  }

  .#{$classPrefix}-close {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 18px;
    height: 18px;
  }
}

.#{$classPrefix}-tips-ok-btn {
  height: 48px;
  border-radius: 8px;
  background: linear-gradient(93.87deg, #ff9630 3.17%, #ff5252 97.04%);
  box-shadow: 0px 8px 14px #ffffff1f, 0px -2px 2px #ffb1aa inset;
}