$classPrefix: 'qk-fileUpload';

.#{$classPrefix}-container {

  --width: 97px;
  --height: 55px;
  --padding: 0px;

  padding: var(--padding);
  position: relative;
  width: 100%;
  min-height: 100px;

  // .#{$classPrefix}-file {
  //   min-height: 38px;
  //   line-height: 38px;
  // }

  .#{$classPrefix}-upload {
    display: inline-block;
    padding: 0px 13px;
    width: 104px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    position: absolute;
    right: 10px;
    bottom: 0;
    border-radius: 16px;
    background: var(--qk-color-primary);
    font-size: 14px;
  }

  .#{$classPrefix}-icon {
    width: 12px;
    height: 12px;
  }

  .#{$classPrefix}-text {
    font-family: var(--qk-font-family-medium);
    font-weight: 500;
    color: #FFFFFF;
    line-height: 24px;
    font-size: 12px;
    text-transform: capitalize;
  }

  .#{$classPrefix}-title {
    font-size: 12px;
    color: #41434D;
    line-height: 18px;
  }

  .#{$classPrefix}-desc {
    font-size: 14px;
    color: #878D99;
    margin-top: 10px;
  }

  .#{$classPrefix}-del{
    position: absolute;
    top: 0;
    right: 0;
  }

  .#{$classPrefix}-filesWrapper {
    position: relative;
    // width: 375px;
    // margin-top: 15px;
    // margin-left: -30px;

    ion-icon {
      color: #D9D9D9;
    }

    ion-label {
      max-width: 240px;
      max-height: 44px;
      overflow: hidden;
      text-align: left;
    }

    >.qk-space {
      min-height: 48px;
      // margin-bottom: 10px;
      padding: 10px 0px;
      // background: #FFFFFF;
      // border-bottom: 1px solid var(--qk-border-color);
      img {
        width: 16px;
        height: 100%;
      }
    }

  }
  &-default{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: #A1A7B2;
    overflow: hidden;
    >.default-img{
      width: 44px;
      height: 45px;
    }
    .file{
      width: 100%;
      height: 100%;
    }
  }
}

