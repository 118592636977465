@import '../../global.scss';
$classPrefix: 'qk-cascader';

.#{$classPrefix} {
  text-align: left;
  &-popup::part(content) {
    width: 100vw;
    margin: 0 !important;
    border-radius: 0;
    padding: 0 !important;
  }
}

.#{$classPrefix}-popup {
  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-search {
      padding: 0 18px;
    }
  }
}

.#{$classPrefix}-box {
  flex: 1;
  display: flex;
  overflow-y: hidden;
  padding: 0 18px;
}
.#{$classPrefix}-left {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding-top: 7px;

  &-content {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  &-list {
    width: 100%;
    --ion-safe-area-left: 0;
    --ion-border-color: var(--qk-border-color);
    display: inline-block;
  }
  &-item {
    @include row-ellipsis;
    --min-height: var(--qk-list-height);
    font-size: 12px;
  }

  &-key {
    height: 24px;
    background: var(--qk-list-back-color);
    border-radius: 2px;
    line-height: 24px;
    font-size: 14px;
    padding: 0 16px;
    > span::before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 11px;
      background-color: var(--qk-color-primary);
      margin-right: 5px;
    }
  }

  &-label {
    color: var(--qk-color-title);
    font-size: 12px;

    &:hover {
      color: var(--qk-color-primary);
    }
  }
}

.#{$classPrefix}-right-link {
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow-y: auto;
  span {
    text-decoration: none;
    width: 16px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--qk-color-weak);
    border-radius: 50%;
    margin: 10px 0;
  }
  & .#{$classPrefix}-right-selectLink {
    background: var(--qk-color-primary);
    color: white;
  }
}

.#{$classPrefix}-close {
  font-size: 28px;
}
