@import '../../../global.scss';
$classPrefix: 'qk-piker';

.#{$classPrefix} {
  @include row-ellipsis();
}

.#{$classPrefix}-container {
  &-modal::part(content) {
    padding-top: 0 !important;
  }

  &-header {
    width: 100%;
    margin: 0;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: var(--qk-color-title);
    position: relative;
    // background-color: var(--qk-core-modal-header-background);
    border-bottom: 1px solid #E7E7E7;
    font-weight: 500;
    &-close {
      font-size: 27px;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
    &-cancel {
      font-size: 16px;
      position: absolute;
      left: 16px;
      top: 0%;
      color: #777777;
    }
    &-confirm {
      font-size: 16px;
      position: absolute;
      right: 16px;
      top: 0%;
      color: #141414;
    }
  }
}


.qk-custom-picker .adm-picker-view-column:after{
  // background-color: rgb(255, 250, 217) !important;
  color: #141414;
  background-color: var(--qk-color-primary) !important;
}