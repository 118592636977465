$class-prefix-step: 'qk-step';
$class-prefix-steps: 'qk-steps';

.#{$class-prefix-step} {
  --line-to-next-color: #e5e5e5;
  --icon-color: #e5e5e5;

  // --title-font-size: var(--qk-font-size-title);
  // --title-font-size: var(--qk-font-size-title);
  // --title-font-size: var(--qk-font-size-title);

  .#{$class-prefix-step}-indicator {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      background-color: var(--line-to-next-color);
    }
    .#{$class-prefix-step}-icon-container {
      position: absolute;
      z-index: 1;
      // background: var(--adm-color-background);
      color: var(--icon-color);
      // > .qk-icon {
      //   display: block;
      // }
    }
  }

  &:last-child {
    .#{$class-prefix-step}-indicator::after {
      display: none;
    }
  }
  &-status-finish {
    --line-to-next-color: var(--qk-color-primary);
  }

  &-status-wait {
    --icon-color: #e5e5e5;
    .qk-step-title {
      color: var(--qk-color-weak);
    }
  }
  &-status-process {
    --icon-color: var(--qk-color-primary);
    .#{$class-prefix-step}-title {
      color: var(--qk-color-primary);
    }
  }
  &-status-finish {
    --icon-color: var(--qk-color-primary);
  }
  &-status-error {
    --icon-color: var(--qk-color-danger);
    .#{$class-prefix-step}-title {
      color: var(--qk-color-danger);
    }
  }
}

.#{$class-prefix-steps} {
  --title-font-size: var(--qk-font-size-title);
  --description-font-size: var(--qk-font-size-desc);
  --indicator-margin-right: 0;
  --icon-size: 18px;

  width: 100%;
  box-sizing: border-box;

  &-horizontal {
    display: flex;
    justify-content: space-around;
    padding: 8px 0;

    .#{$class-prefix-step} {
      flex: 1;

      .#{$class-prefix-step}-indicator {
        width: 100%;
        height: 24px;
        &::after {
          left: 50%;
          top: 50%;
          height: 1px;
          transform: translateY(-50%);
          width: 100%;
        }
        .#{$class-prefix-step}-icon-container {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .#{$class-prefix-step}-content {
      text-align: center;
      font-size: var(--description-font-size);
      padding: 2px 8px 0;
      .#{$class-prefix-step}-title {
        font-size: var(--title-font-size);
      }
      .#{$class-prefix-step}-description {
        margin-top: 4px;
        color: var(--qk-color-weak);
      }
    }
  }

  &-vertical {
    padding: 8px 0px;

    .#{$class-prefix-step} {
      display: flex;
      align-items: stretch;

      .#{$class-prefix-step}-indicator {
        flex: none;
        width: 24px;
        margin-right: var(--indicator-margin-right);

        &::after {
          left: 50%;
          top: calc(var(--title-font-size) * 1 / 2);
          width: 1px;
          transform: translateX(-50%);
          height: 100%;
        }
        .#{$class-prefix-step}-icon-container {
          top: calc(var(--title-font-size) * 1 / 2);
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:last-child {
        .#{$class-prefix-step}-content {
          padding-bottom: 0;
        }
      }
      .#{$class-prefix-step}-content {
        flex: auto;
        padding-bottom: 15px;
        .#{$class-prefix-step}-title {
          font-size: var(--title-font-size);
          line-height: 1.5;
        }
        .#{$class-prefix-step}-description {
          padding-top: 4px;
          font-size: var(--description-font-size);
          color: var(--qk-color-weak);
        }
      }
    }
  }
}

.#{$class-prefix-step}-icon-container {
  font-size: var(--icon-size);
}

.#{$class-prefix-step}-icon-dot {
  display: block;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 6px;
  border-width: 4px;
  border-color: var(--qk-color-primary);
  border-style: solid;
}
