$class-prefix-qk-segment: 'qk-segment';

.#{$class-prefix-qk-segment} {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  margin-bottom: 20px;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
    min-height: 30px;
    text-align: center;
    justify-content: center;

    &-text {
      // font-size: 15px;
      // color: var(--qk-color-primary);
      font-size: 14px;
      color: var(--qk-color-title);
    }

    &-indicator {
      width: 100%;
      height: 0px;
      visibility: hidden;
    }

    &-selected {
      .#{$class-prefix-qk-segment}-item-text {
        font-size: 15px;
        color: var(--qk-color-primary);
      }

      .#{$class-prefix-qk-segment}-item-indicator {
        width: 100%;
        height: 1px;
        background: var(--qk-color-primary);
        visibility: visible;
      }
    }
  }
}