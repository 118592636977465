.adm-check-list-item {
  &-extra {
    font-size: var(--adm-font-size-8);
    line-height: 1;
    color: var(--adm-color-primary);
  }

  &-readonly {
    cursor: unset;
  }
}