.tab {
  background-color: transparent;
  >img {
    height: 22px;
  }
  >ion-label {
    font-size: 10px;
  }
}

.tab-hidden {
  visibility: hidden;
  height: 0.01px;
}

.tab-show {
  visibility: visible;
  padding: 10px 0;
}

.tab-selected{
  color: var(--qk-color-primary);
}