.qk-page-background {
  background: white;
  background-size: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.qk-base-page-fixed {
  // z-index: 1;
}

ion-backdrop {
  opacity: 1;
  background-color: transparent;
}

.qk-content {
  position: relative;
  height: 100%;
  overflow: auto;
}

.qk-content-keyboard{
  --keyboard-offset : 0px !important;
}