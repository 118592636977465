.qk-contact-group {
  overflow: hidden;
  &-add {
    margin: 0 18px;
    border: 0.5px solid var(--qk-color-placeholder);
    height: 45px;
    line-height: 30px;
    text-align: center;
    font-size: 15px;
    color: var(--qk-color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    ion-icon {
      color: var(--qk-color-primary);
    }
  }
}

.qk-contact-group-title {
  font-size: 16px;
  color: rgb(29, 33, 41);
  line-height: 24px;
  margin: auto;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.qk-contact-group-required {
  position: absolute;
  width: 12px;
  height: 20px;
  margin-top: 3px;
  margin-left: 3px;
  color: var(--qk-color-danger);
}

.qk-contact-item-container{
  .qk-contact-item-relations{
    .DefaultFormItemWrapper{
      background: transparent;
      height: max-content;
      padding: 0;
      margin-bottom: 10px;
    }
  }
  .radio.relation-radio {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .qk-contact-item-form-radio {
      width: 164px;
      height: 57px;
      border-radius: 8px;
      margin-bottom: 10px;
      position: relative;
      &-tag{
        position: absolute;
        top: 0px;
        right: 0;
      }
      .qk-contact-item-radio {
        width: 100%;
        height: 100%;
        background-color: rgb(237, 238, 240);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: rgb(32, 32, 38);
        font-weight: 600;
        padding: 0;
      }
      .qk-contact-item-radio.qk-contact-item-radio-checked {
        border: 2px solid var(--qk-color-primary);
        background: #e8effd;
        box-shadow: 0px 5px 25px rgba(217, 217, 217, 0.1);
      }
    }
  }
}