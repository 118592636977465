$class-prefix-error-block: 'qk-error-block';

.#{$class-prefix-error-block} {
  --color: var(--qk-color-text);
  --image-height: 135px;
  --image-height-full-page: 135px;
  --image-width: auto;
  --image-width-full-page: auto;

  box-sizing: border-box;
  text-align: center;

  &-image {
    height: var(--image-height);
    width: var(--image-width);
    max-width: 100%;

    & svg,
    img {
      height: 100%;
    }
  }

  &-description {
    font-size: var(--qk-font-size-text);
    color: #999;
    line-height: 1.4;
    margin-top: 12px;
    white-space: pre-wrap;

    &-title {
      font-size: var(--qk-font-size-title);
    }

    &-subtitle {
      font-size: var(--qk-font-size-subtitle);
      font-family: var(--qk-font-family-medium);
      font-weight: 500;
      color: #333333;
      margin-top: 8px;
    }

    &-desc {
      margin-top: 8px;
    }
  }

  &-content {
    margin-top: 12px;
  }

  &-retry {
    margin-top: 28px;
    --background: transparent;
    --color: var(--qk-color-primary);
    --border-color: var(--qk-color-primary);
    --border-width: 0px;
    --border-style: solid;
    --border-radius: 24px;
    --padding-start: 80px;
    --padding-end: 80px;
    // height: 36px;
    width: auto;
    display: inline-block;

    height: 48px;
    box-shadow: 0px 4px 10px 0px rgba(17, 73, 46, 0.2);
    // border-radius: 24px;
    // border: 1px solid #0FB77A;
  }

  &-full-page {
    padding-top: calc(50vh - var(--image-height-full-page));

    .#{$class-prefix-error-block}-image {
      // height: var(--image-height-full-page);
      width: var(--image-width-full-page);
      display: flex;
      justify-content: center;
    }

    .#{$class-prefix-error-block}-description {
      margin-top: 20px;
      font-size: var(--qk-font-size-text);

      &-title {
        font-size: var(--qk-font-size-title);
        color: var(--qk-color-title);
      }
    }
  }
}