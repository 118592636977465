.qk-modal {
  padding-bottom: calc(env(safe-area-inset-bottom) + var(--padding-bottom));
  padding-top: var(--padding-top, 0px);
  padding-left: var(--padding-left, 0px);
  padding-right: var(--padding-right, 0px);

  &-content {
  }

  &-center {
    border-radius: 10px;
    overflow: auto;
    padding: 20px;
  }

  &-bottom {
    border-radius: 10px 10px 0px 0px;
    overflow: auto;
  }

  // &-full-page {
  //   --height: 100%;
  //   --border-radius: 0px;
  // }

  &-button {
    width: 120px;
    height: 44px;
  }
}
