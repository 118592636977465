@import '../../global.scss';
.search {
  padding: 0;
}

:global {
  .searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
    padding-left: 30px;
  }

  .searchbar-search-icon.sc-ion-searchbar-ios {
    width: 18px;
    left: 10px;
  }
  .searchbar-input.sc-ion-searchbar-ios {
    font-size: 12px;
    border-radius: 5px;
  }
}
