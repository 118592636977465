$classPrefix: 'custom-close-modal';

.#{$classPrefix} {
  padding: 0;
  overflow: visible;

  .logo{
    width: 60px;
    height: 69px;
  }

  &-top {
    border-radius: 4px 4px 0px 0px;
    padding: 20px 8px;

    .custom-modal-bg {
      width: 77px;
      height: 77px;
      margin-top: -55px;
      margin-bottom: 10px;
    }
  }

  &-bottom {
    padding: 0px 20px 20px 20px;
  }

  &-button-confirm {
    width: 132px;
    height: 48px;
    border-radius: 28px;
    background: rgb(157, 124, 248);
    box-shadow: 0px 2px 8px rgba(52, 0, 0, 0.21);
  }

  &-button-cancel {
    width: 132px;
    height: 48px;
    // box-shadow: 0px 2px 5px 0px rgba(97, 149, 111, 0.25);
    --background: #E8EFFD;
    color: var(--qk-color-primary);
    border-radius: 28px;
    // border: 1px solid rgb(157, 124, 248);
    box-sizing: border-box;
  }
}