
.qk-item-size-normal {
  --min-height: 50px;
}

.qk-item-size-large {
  --min-height: 63px;
}

.qk-item-size-small {
  --min-height: 36px;
}

.qk-item {

  font-size: var(--qk-font-size-text);

  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --icon-arrow-color: #E1E3E4;
  --border-color:  var(--qk-border-color);
  --border-radius:0px;
  margin-bottom: 8px;

  width: 100%;

  // &::part(native) {
    // padding-left: 0px;
    // padding-right: 0px;
  // }

  &::part(detail-icon) {
    color: var(--icon-arrow-color);
    opacity: 1;
    margin-left: 8px;
    padding: 8px 0;
  }
}

.qk-item-h-normal {
  --padding-start: 20px;
  --padding-end: 20px;
}

.qk-item-h-small {
  --padding-start: 18px;
  --padding-end: 18px;
}