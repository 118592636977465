.qk-input {

  --font-size: var(--qk-font-size-text);
  --color: var(--qk-color-title);
  --placeholder-color: var(--qk-color-placeholder);
  --text-align: left;

  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  max-height: 100%;

  // height: 50px;
  // border-radius: 10px;
  // border: 0.5px solid #BDBDBD;

  // padding: 0px 0px 0px 10px;

  &.focused {
    border-color: var(--qk-color-primary);
  }
  
  &.error {
    border-color: var(--qk-color-danger);
  }

  .pollo-input-item {
    flex: auto;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    background: transparent;
    line-height: 1.5;
    text-align: var(--text-align);
    caret-color: var(--qk-color-primary);
    color: var(--color);
    // font-size: var(--font-size);
    font-size: 14px;

    &::placeholder {
      font-size: var(--font-size);
      color: var(--placeholder-color);
    }

    &:-webkit-autofill {
      background-color: transparent;
    }

    &:read-only {
      cursor: default;
    }

    &:invalid {
      box-shadow: none;
    }

    &::-ms-clear {
      display: none;
    }

    &::-ms-reveal {
      display: none;
    }

    &::-webkit-search-cancel-button {
      display: none;
    }

    &::-webkit-search-decoration {
      display: none;
    }

    &:disabled {
      opacity: 1;
    }
    // for ios
    &[type='date'],
    &[type='time'],
    &[type='datetime-local'] {
      min-height: 1.5em;
    }

    // for safari
    &[type='search'] {
      -webkit-appearance: none;
    }

    &[readonly] {
      pointer-events: none;
    }
  }

  .pollo-input-item-clear {
    flex: none;
    margin-left: 8px;
    // padding: 4px;
    cursor: pointer;
  }
}

.qk-input-error {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;

  margin-top: 6px;
  margin-bottom: 6px;

  .qk-input-error-icon {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  .qk-input-error-text {
    font-size: 12px;
    color: var(--qk-color-danger);
  }

}