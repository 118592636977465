$class-prefix-popup: 'qk-popup';

.#{$class-prefix-popup} {
  --z-index: var(--qk-popup-z-index, 1000);

  position: fixed;
  z-index: var(--z-index);

  &-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
  }
}

.#{$class-prefix-popup}-body {
  position: fixed;
  background-color: var(--qk-popup-color-background, white);
  z-index: calc(var(--z-index) + 10);

  $close-button-space: 17px;

  .#{$class-prefix-popup}-close-icon {
    position: absolute;
    z-index: 100;
  }

  &-position-bottom {
    width: 100%;
    bottom: 0;
    left: 0;
    .#{$class-prefix-popup}-close-icon {
      right: $close-button-space;
      top: $close-button-space;
    }
  }
  &-position-top {
    width: 100%;
    top: 0;
    left: 0;
    .#{$class-prefix-popup}-close-icon {
      right: $close-button-space;
      bottom: $close-button-space;
    }
  }
  &-position-left {
    height: 100%;
    top: 0;
    left: 0;
    .#{$class-prefix-popup}-close-icon {
      right: $close-button-space;
      top: $close-button-space;
    }
  }
  &-position-right {
    height: 100%;
    top: 0;
    right: 0;
    .#{$class-prefix-popup}-close-icon {
      left: $close-button-space;
      top: $close-button-space;
    }
  }

  &-position-center {
    height: auto;
    left: 22px;
    right: 22px;
    // border-radius: var(--qk-popup-center-border-radius, 10px);

    .#{$class-prefix-popup}-close-icon {
      right: $close-button-space;
      top: $close-button-space;
    }
  }
}

.#{$class-prefix-popup}-close-icon {
  cursor: pointer;
  padding: 4px;
  font-size: 18px;
  color: var(--qk-color-weak);

  svg {
    width: 20px;
    height: 20px;
  }
}
