$classPrefix: 'qk-picker-view';

.#{$classPrefix}-content {
  width: 100%;
  // max-height: calc(40px*5);
  // padding-bottom: 20px;
  overflow-y: auto;

  &-itemList {
    --min-height: 40px;
  }

  &-item {
    color: var(--qk-color-weak);
    text-align: center;

    &:hover {
      color: var(--qk-color-primary);
    }

    &.select {
      font-weight: 500;
      color: var(--qk-color-primary);
    }
  }
}

@keyframes run {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.qk-custom-picker {
    .adm-picker-header-title {
      font-size: 32px;
      font-family: Roboto-Medium, PingFangSC-Medium;
      font-weight: 500;
      color: #333333;
      line-height: 32px;
    }

    .adm-picker-header {
      .adm-picker-header-button {
        min-width: 80px;

        &:active,
        &:hover {
          color: var(--adm-color-primary);
        }
      }

      // >a:hover {
      //   color: @primary-focus-border-color;
      // }
    }

    .adm-picker-view-column-item {
      color: rgba(0, 0, 0, 0.26);
    }

    .adm-picker-view-column-wheel {
      >.adm-picker-view-column-item:not([data-selected="false"]) {
        color: #fff;
        font-weight: 500;
      }
    }

    .adm-picker-view-column {

      &:after {
        position: absolute;
        left: 8px;
        top: calc(50% - var(--item-height)/2);
        height: var(--item-height);
        width: calc(100% - 16px);
        content: "";
        z-index: -1;
        border-radius: 8px;
        background-color: #FFF0EF;
      }
    }

    .adm-picker-view-mask-middle {
      border: none;
    }

    .adm-picker-view-column-item-label {
      // padding: 0px 50px;
    }

  

  // .labelSelected {
  //   color: @primary-focus-border-color;
  // }
}