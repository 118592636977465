$class-prefix-infinite-scroll: 'qk-infinite-scroll';

.#{$class-prefix-infinite-scroll} {
  color: var(--qk-color-weak);
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--qk-font-size-text);

  &-failed-text {
    display: inline-block;
    margin-right: 8px;
  }
}
