.adm-text-area {
    --font-size: var(--adm-font-size-9);
    --color: var(--adm-color-text);
    --placeholder-color: var(--adm-color-light);
    --disabled-color: var(--adm-color-weak);
    --text-align: left;
    --count-text-align: right;
    position: relative;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    background: #fff;
    border-radius: 14px;
    box-sizing: border-box;
    padding: 16px 12px;
  }
  
  .adm-text-area-element {
    font-family: var(--adm-font-family);
    resize: none;
    flex: auto;
    display: block;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    margin: 0;
    color: var(--color);
    font-size: var(--font-size);
    line-height: 1.5;
    background: transparent;
    border: 0;
    outline: none;
    appearance: none;
    min-height: 1.5em;
    text-align: var(--text-align);
  
    &::placeholder {
      color: #707579;
      font-family: inherit;
      font-size: 14px;
    }
  
    &:-webkit-autofill {
      background-color: transparent;
    }
  
    &:disabled {
      color: var(--disabled-color);
      cursor: not-allowed;
      opacity: 1;
      -webkit-text-fill-color: var(--disabled-color);
    }
  
    &:read-only {
      cursor: default;
    }
  
    &:invalid {
      box-shadow: none;
    }
  
    &::-ms-clear {
      display: none;
    }
  
    &[readonly] {
      pointer-events: none;
    }
  
    &-hidden {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1000;
    }
  }
  
  .adm-text-area-count {
    text-align: var(--count-text-align);
    color: var(--adm-color-weak);
    font-size: var(--adm-font-size-9);
    padding-top: 8px;
  }