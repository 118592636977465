$classPrefix: 'qk-emailInput';
.#{$classPrefix}-row {
  position: relative;
  box-sizing: border-box;
  &-box {
    width: 250px;
    max-height: 240px;
    box-shadow: 0px 0px 3px 3px #d4cece49;
    position: absolute;
    top: 30px;
    left: 20px;
    background: white;
    overflow-y: auto;
    border-radius: 5px;
    z-index: 1;
    padding: 0 10px;
    &-item {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #e3e3e3df;
      font-size: 12px;
    }

    &-item:hover {
      opacity: 0.8;
    }
  }
}
