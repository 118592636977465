$class-prefix-popover: 'qk-popover';

.#{$class-prefix-popover} {
  --z-index: var(--qk-popover-z-index, 1030);

  --background: #ffffff;
  --arrow-size: 8px;
  --content-padding: 8px 12px;

  // &&-dark {
  //   --background: rgba(0, 0, 0, 0.75);
  //   --qk-color-text: #ffffff;
  //   color: #ffffff;
  //   .qk-popover-inner {
  //     box-shadow: none;
  //   }
  // }

  color: #333333;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index);
  white-space: normal;
  text-align: left;
  cursor: auto;
  user-select: text;
  animation: none;

  &::after {
    position: absolute;
    background: fade(#ffffff, 1%);
    content: '';
  }
  &-hidden {
    display: none;
  }

  &-inner {
    background-color: var(--background);
    background-clip: padding-box;
    border-radius: 8px;
    box-shadow: 0 0 30px 0 rgba(51, 51, 51, 0.2);
    font-size: var(--qk-font-size-7);
    width: max-content;
    min-width: 32px;
    max-width: calc(100vw - 24px);
    overflow-y: hidden;
    &-content {
      padding: var(--content-padding);
    }
  }

  &-arrow {
    position: absolute;
    display: block;
    height: var(--arrow-size);
    width: var(--arrow-size);
    overflow: visible;
    background: transparent;
  }

  &-arrow-icon {
    display: block;
    height: var(--arrow-size);
    width: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(var(--arrow-icon-rotate));
  }

  .qk-popover-arrow {
    color: var(--background);
  }
}
