
.qk-label {
  font-size: var(--qk-font-size-text, 14px);
}

.qk-label-size-large {
  font-size: 21px !important;
}

.qk-label-size-title {
  font-size: var(--qk-font-size-title, 18px) !important;
}

.qk-label-size-subtitle {
  font-size: var(--qk-font-size-subtitle, 16px) !important;
}

.qk-label-size-text {
  font-size: var(--qk-font-size-text, 14px) !important;
}

.qk-label-size-desc {
  font-size: var(--qk-font-size-desc, 12px) !important;
}

.qk-label-size-tiny {
  font-size: var(--qk-font-size-tiny, 10px) !important;
}

.qk-label-medium {
  --ion-font-family: var(--qk-font-family-medium);
  font-family: var(--qk-font-family-medium);
}

.qk-label-bold {
  font-weight: bold;
}