@class-prefix-picker-view: ~'adm-picker-view';

.@{class-prefix-picker-view} {
  --height: 240px;
  --item-height: 34px;
  --item-font-size: var(--adm-font-size-8);
  height: var(--height);
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  background: var(--adm-color-background);
}

.@{class-prefix-picker-view}-column {
  height: 100%;
  flex: 1;
  user-select: none;
  touch-action: none;
  position: relative;
  z-index: 0;
  &-wheel {
    width: 100%;
    cursor: grab;
    position: absolute;
    top: calc(50% - var(--item-height) / 2);
    left: 0;
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      width: 100%;
      height: 100vh;
      top: -100vh;
    }
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      width: 100%;
      height: 100vh;
      bottom: -100vh;
    }
  }
  &-item {
    font-size: var(--item-font-size);
    padding: 0 6px;
    height: var(--item-height);
    display: flex;
    justify-content: center;
    align-items: center;
    &-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.@{class-prefix-picker-view}-column-accessible {
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  padding-bottom: 1px;
  > * {
    flex: 1;
    text-overflow: ellipsis;
  }
  &-current {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &-button {
    width: 100%;
    height: 100%;
  }
}

.@{class-prefix-picker-view}-mask {
  position: absolute;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  &-top,
  &-bottom {
    flex: auto;
  }
  &-middle {
    height: var(--item-height);
    box-sizing: border-box;
    flex: none;
    border-top: solid 1px var(--adm-color-border);
    border-bottom: solid 1px var(--adm-color-border);
  }
  &-top {
    background: var(--adm-color-background);
    mask: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(0, 0, 0, 1) 100%
    );
  }
  &-bottom {
    background: var(--adm-color-background);
    mask: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(0, 0, 0, 1) 100%
    );
  }
}

.@{class-prefix-picker-view}-loading-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
