.qk-card {
  --border-radius: 12px;

  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: var(--border-radius);
  padding: 0px 15px;
  overflow: hidden;
}

.qk-card-vertical-padding {
  padding-top: 15px;
  padding-bottom: 15px;
}