$classPrefix: 'qk-contact-item';

@mixin qk-contect-item-content {
  min-height: 55px;
  border-radius: 0;
}

.#{$classPrefix}-container {
  .qk-formItem-3-required {
    display: none;
  }

  &-formItem {
    padding: 0 20px;

    &.formItemPhone {
      --qk-core-border-color: white;
    }

    &-formItemDisabled {
      pointer-events: none;
    }

    .content {
      @include qk-contect-item-content();
    }

    &-control {
      // --text-align: right;
    }
  }

  &-box {
    border: 0 !important;

    .imgIcon {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      >span {
        font-size: 16px;
        margin-top: 5px;
        color: #A9ACB0;
      }

      >img {
        width: 25px;
      }
    }
  }

  &-contactNumber {
    @include qk-contect-item-content();
    border: none;
  }
}